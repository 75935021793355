/* Signature font */
@import url('https://fonts.googleapis.com/css2?family=Style+Script&display=swap');


/* Apply signature font to input field */
.signature-input {
    font-family: "Style Script", cursive;
    font-size: 16px;
    /* Adjust font size as needed */
    padding: 5px;
    /* Adjust padding as needed */
    width: 300px;
    /* Adjust width as needed */
}

/* Style container for signature display */
.signature-container {
    font-family: "Style Script", cursive;
    font-size: 24px;
    font-weight: 900;
    color: #d10075 !important;
    /* Adjust font size as needed 
    padding: 10px;*/
    /* Adjust padding as needed 
    border: 1px solid #ccc;*/
    /* Add border if desired */
    width: fit-content;
    /* Adjust width as needed 
    margin-top: 10px;*/
    /* Adjust margin as needed */
}
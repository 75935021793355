@import url(https://fonts.googleapis.com/css2?family=Style+Script&display=swap);
html {
    scroll-behavior: smooth;
}

body:has(.noScroll) {
    -ms-overflow-style: none;
}

body:has(.noScroll)::-webkit-scrollbar {
    display: none;
}

.apexcharts-menu {
    background: #333 !important;
}

.apexcharts-menu .apexcharts-menu-item {
    color: "white" !important;
}

body>iframe[style*='2147483647'] {
    display: none;
}

.css-m01nxn {
    display: none;
}

.custom-scrollbar {
    scrollbar-width: thin !important;
    scrollbar-color: #454f5bc7 transparent !important;
}

.custom-scrollbar::-webkit-scrollbar {
    width: 6px !important;
    height: 6px !important;
}

.custom-scrollbar::-webkit-scrollbar-track {
    background: transparent !important;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: #454f5bc7 !important;
    border-radius: 8px !important;
    border: 0px solid #ffffff !important;
}

ul {
    padding-left: 32px;
}

.pulse-button {
    display: block;
    font-size: 1.3em;
    font-weight: light;
    font-family: 'Trebuchet MS', sans-serif;
    text-transform: uppercase;
    text-align: center;
    line-height: 100px;
    letter-spacing: -1px;
    color: white;
    border: none;
    border-radius: 50%;
    background: #5a99d4;
    cursor: pointer;
    box-shadow: 0 0 0 0 rgba(#5a99d4, .5);
    -webkit-animation: pulse 1.5s infinite;
  }

  .pulse-button:hover {
    -webkit-animation: none;
  }
/* Signature font */


/* Apply signature font to input field */
.signature-input {
    font-family: "Style Script", cursive;
    font-size: 16px;
    /* Adjust font size as needed */
    padding: 5px;
    /* Adjust padding as needed */
    width: 300px;
    /* Adjust width as needed */
}

/* Style container for signature display */
.signature-container {
    font-family: "Style Script", cursive;
    font-size: 24px;
    font-weight: 900;
    color: #d10075 !important;
    /* Adjust font size as needed 
    padding: 10px;*/
    /* Adjust padding as needed 
    border: 1px solid #ccc;*/
    /* Add border if desired */
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    /* Adjust width as needed 
    margin-top: 10px;*/
    /* Adjust margin as needed */
}

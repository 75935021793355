html {
    scroll-behavior: smooth;
}

body:has(.noScroll) {
    -ms-overflow-style: none;
}

body:has(.noScroll)::-webkit-scrollbar {
    display: none;
}

.apexcharts-menu {
    background: #333 !important;
}

.apexcharts-menu .apexcharts-menu-item {
    color: "white" !important;
}

body>iframe[style*='2147483647'] {
    display: none;
}

.css-m01nxn {
    display: none;
}

.custom-scrollbar {
    scrollbar-width: thin !important;
    scrollbar-color: #454f5bc7 transparent !important;
}

.custom-scrollbar::-webkit-scrollbar {
    width: 6px !important;
    height: 6px !important;
}

.custom-scrollbar::-webkit-scrollbar-track {
    background: transparent !important;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: #454f5bc7 !important;
    border-radius: 8px !important;
    border: 0px solid #ffffff !important;
}

ul {
    padding-left: 32px;
}

.pulse-button {
    display: block;
    font-size: 1.3em;
    font-weight: light;
    font-family: 'Trebuchet MS', sans-serif;
    text-transform: uppercase;
    text-align: center;
    line-height: 100px;
    letter-spacing: -1px;
    color: white;
    border: none;
    border-radius: 50%;
    background: #5a99d4;
    cursor: pointer;
    box-shadow: 0 0 0 0 rgba(#5a99d4, .5);
    -webkit-animation: pulse 1.5s infinite;
  }

  .pulse-button:hover {
    -webkit-animation: none;
  }
  
  @-webkit-keyframes pulse {
    0% {
      @include transform(scale(.9));
    }
    70% {
      @include transform(scale(1));
      box-shadow: 0 0 0 50px rgba(#5a99d4, 0);
    }
      100% {
      @include transform(scale(.9));
      box-shadow: 0 0 0 0 rgba(#5a99d4, 0);
    }
  }